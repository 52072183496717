import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { StaticVariable } from '../../globle.class';
import { RoomMasterPojo } from "app/management/roommaster/roommaster.pojo";
declare var bootbox: any;

const httpOptions = {
    headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
};

@Injectable()

export class RoomMasterService {

    constructor( private httpclient: HttpClient, private http: Http ) { }

    getRoomMaster(): Observable<RoomMasterPojo[]> {
        const url = StaticVariable.porturl + 'RoomMasterAll';
        return this.httpclient.get<RoomMasterPojo[]>( url );
    }

    getRoomByRegionId( id: number ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomTypeMasterByRegionId/' + id;
        return this.httpclient.get<any>( url );
    }

    getRoomMasterRegionId( id: number ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomMasterByRegionId/' + id;
        return this.httpclient.get<any>( url );
    }
    
    getRoomMasterByCottageId( id: number ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomMasterByCottageId/' + id;
        return this.httpclient.get<any>( url );
    }

    addRoomMaster( arg: RoomMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddRoomMaster';
        return this.http.post( url, arg );
    }

    deleteRoomMaster( arg: number ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomMasterDelete/' + arg;
        return this.httpclient.delete<any>( url );
    }

    updateRoomMaster( data: RoomMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomMasterUpdate';
        return this.http.put( url, data );
    }

}
