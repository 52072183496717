import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from "rxjs";
import { StaticVariable } from "app/globle.class";
import { CottagePojo } from "app/management/cottagemaster/cottage.pojo";
import { CottageRoomTypePojo } from "app/management/LodgeCheckIn/room-check-in/room-check-in.pojo";
import { RoomTransactionPojo, IdProofTypePojo } from "app/user/excess/required.pojo";
import { ReceiptItemDetails } from "app/management/LodgeCheckIn/Pojo/ReceiptItemDetailsPojo";
import { FtpImagePojo } from "app/management/LodgeCheckIn/Pojo/FtpImagePojo";
import { DummyReceiptDetailPojo } from "app/management/LodgeCheckIn/Pojo/dummyReceiptDetail.pojo";
import { CheckInReceiptPojo } from "app/management/LodgeCheckIn/checkin-user-details/checkin-user-details.pojo";
import { UploadFilePojo } from './uploadFilePojo';

const httpOptions = { headers: new HttpHeaders( { 'Content-Type': 'application/json' } ) };

@Injectable()

export class CheckInFormService {

    constructor( private http: Http, private httpclient: HttpClient ) { }

    getReceiptItem(): Observable<any[]> {
        const url = StaticVariable.porturl + 'ReceiptItemAll';
        return this.httpclient.get<any[]>( url );
    }

    getCustomerRecordByMobileNumber( mobileNo: string ): Observable<any> {
        const url = StaticVariable.porturl + 'CheckInSearchByMobileNo/' + mobileNo;
        return this.httpclient.get<any>( url );
    }

    addCheckInFormDetails( arg: DummyReceiptDetailPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddCheckIn';
        return this.httpclient.post( url, arg );
    }

    getAllIdProofType(): Observable<IdProofTypePojo[]> {
        const url = StaticVariable.porturl + 'IdProofTypeAll';
        return this.httpclient.get<IdProofTypePojo[]>( url );
    }

    addReceiptDetails( arg: CheckInReceiptPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddCheckInReceipt';
        return this.http.post( url, arg );
    }

    getCheckInReceiptByCheckInId( id: number ): Observable<any> {
        const url = StaticVariable.porturl + 'CheckInReceiptByCheckInId/' + id;
        return this.httpclient.get<any>( url );
    }

    uploadCheckInProfileImage( file: File ): Observable<FtpImagePojo> {
        let formData: any = new FormData();
        formData.append( 'file', file, file.name );
        const url = StaticVariable.porturl + 'AddFtpImage';
        return this.httpclient.post<FtpImagePojo>( url, formData );
    }

    getCheckInReceiptById(checkinid:number):Observable<any>{
      const httpOptions = {
        'responseType' :'Blob' as 'json'
      };
      const url= StaticVariable.porturl +'GetCheckInReceiptById/' +checkinid;
      return this.httpclient.get<any>(url,httpOptions);
    }

    addFile(file:File):Observable<any>{

           let formData: any = new FormData();
           formData.append('file', file, file.name);
           const url =StaticVariable.porturl+'AddFile';
          return this.httpclient.post<any>(url,formData);

    }





}
