import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
/*import { AgmCoreModule } from '@agm/core';*/

import { AppComponent } from './app.component';

import { HttpClientModule } from "@angular/common/http";
import { UserAuthGuard } from "app/user-authentication/user-auth.guard";
/*import { DisclaimerComponent } from "app/footer/disclaimer/disclaimer.component";
import { ConditionsComponent } from "app/footer/conditions/conditions.component";
import { HelpComponent } from "app/footer/help/help.component";
import { PrivacyComponent } from "app/footer/privacy/privacy.component";
import { PaymentComponent } from "app/footer/payment/payment.component";
import { RefundComponent } from "app/footer/refund/refund.component";*/
import { environment } from "environments/environment";
import { LodgeAuthGuard } from "app/user-authentication/Lodge-auth.guard";
import { UserComponent } from './user/user.component';
import { TransactionComponent } from './user/transaction/transaction.component';
import { LoginComponent } from './user/login/login.component';
import { RegisterComponent } from './user/register/register.component';
import { AuthService } from './user/auth.service';
import { RoomTypeMasterService } from './management/roomtypemaster/roomtypemaster.service';
import { RoomMasterService } from './management/roommaster/roommaster.service';
import { RoomRegionService } from './management/roomregion/roomregion.service';
import { OnlineBookingService } from './management/LodgeCheckIn/onlinebooking/onlinebooking.service';
import { CheckInFormService } from './management/LodgeCheckIn/check-in-form/check-in-form.service';
import { LodgeAppService } from './management/LodgeCheckIn/lodge-app/lodge-app.service';
import { AuthGuard } from './user/login/auth.guard';
/*if (environment.production) {
    enableProdMode();
  }*/

const appRoutes: Routes = [
    { path: '', redirectTo: 'userlogin', pathMatch: 'full' },
    {
        path: 'management',

        loadChildren: './management/management.module#ManagementModule',
    },
    {
        path:'userlogin', component:LoginComponent
    },
    {
        path:'register', component:RegisterComponent
    },
    {
        path:'user',component:UserComponent,canActivate: [AuthGuard] 
    }
 ];

@NgModule( {
    declarations: [
        AppComponent,
        UserComponent,
        TransactionComponent,
        LoginComponent,
        RegisterComponent,
       


    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        
        /*   AgmCoreModule.forRoot( { apiKey: 'AIzaSyBwglMNXTtRXOMLxVR7M_DtoF1wEbTiVMg' } ),*/
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: true,useHash:true } // <-- debugging purposes only
        )
    ],
    providers: [UserAuthGuard,LodgeAuthGuard,AuthService,RoomMasterService,RoomTypeMasterService,RoomRegionService,OnlineBookingService,CheckInFormService,LodgeAppService,AuthGuard],
    bootstrap: [AppComponent]
} )
export class AppModule { }
