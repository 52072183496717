import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { StaticVariable } from '../../globle.class';
import { RoomTypeMasterPojo } from "app/management/roomtypemaster/roomtypemaster.pojo";

declare var bootbox: any;

const httpOptions = {
    headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
};

@Injectable()

export class RoomTypeMasterService {

    constructor( private httpclient: HttpClient, private http: Http ) { }
    
    getRoomTypeMaster(): Observable<RoomTypeMasterPojo[]> {
        const url = StaticVariable.porturl + 'RoomTypeMasterAll';
        return this.httpclient.get<RoomTypeMasterPojo[]>( url );
    }

    getRoomTypeMasterForOnlineAndOffline(): Observable<RoomTypeMasterPojo[]> {
        const url = StaticVariable.porturl + 'RoomTypeMasterAllForOnlineAndOffline';
        return this.httpclient.get<RoomTypeMasterPojo[]>( url );
    }
    
    addRoomTypeMaster( arg: RoomTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddRoomTypeMaster';
        return this.http.post( url, arg );
    }

    deleteRoomTypeMaster( arg: number ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomTypeMasterDelete/' + arg;
        return this.httpclient.delete<any>( url );
    }

    updateRoomTypeMaster( data: RoomTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomTypeMasterUpdate';
        return this.httpclient.put( url, data );
    }
}
