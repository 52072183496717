import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from "rxjs";
import { StaticVariable } from "app/globle.class";

@Injectable()

export class LodgeAppService {

    constructor( private http: Http, private httpclient: HttpClient ) { }
    loadOnlineRooms(): Observable<any> {
        const url = StaticVariable.porturl + 'AddBookingOnlineToOffline';
        return this.httpclient.get( url );
    }
}
