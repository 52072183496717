import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { C } from '@angular/core/src/render3';
//import { Http } from '@angular/http';
import { StaticVariable } from 'app/globle.class';
import { ResponsePojo } from 'app/management/LodgeCheckIn/Pojo/response.pojo';
import { ManagementUserPojo, UserPojo } from 'app/management/management-usermaster/managementuser.pojo';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private isAuthenticated = false;

  constructor( private httpclient: HttpClient) { }

  // login(userLogin: UserPojo): Observable<any> {
  //   const url = StaticVariable.porturl + 'UserLogin';
  //   return this.httpclient.post(url, userLogin);
  // }

  login(userLogin: UserPojo): Observable<any> {
    const url = StaticVariable.porturl + 'UserLogin';
    return this.httpclient.post(url, userLogin).pipe(
      tap((response: any) => {
        if (response.rpojo.responseText !== 'INVALID PASSWORD') {
          this.isAuthenticated = true;  // Set to true on successful login
        }
      })
    );
  }

  register(userData:UserPojo): Observable<any> {
    const url = StaticVariable.porturl + 'UserRegister';
    return this.httpclient.post<any>(url, userData);
  }
  
  checkMobileNoForRegisterVerification(mobileNo): Observable<ResponsePojo> {
    const url = StaticVariable.porturl + 'checkMobileNoForSignUpVerification'+'/'+mobileNo;
    return this.httpclient.get<ResponsePojo>(url);
}
updatePassword(arg:UserPojo): Observable<UserPojo> {
  const url = StaticVariable.porturl + 'UpdatePassword';
  return this.httpclient.post<UserPojo>(url,arg);
}


checkAuthenticated(): boolean {
  return this.isAuthenticated;  // Returns the authentication status
}

logout(): void {
  this.isAuthenticated = false;  // Set to false on logout
}
}
