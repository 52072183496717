export class LoggedUserDetails {
    loggedUserId: number;
    transactionFlag: string;
    transactionDateTime: Date;
    transactionIp: string;
    name: string;
    userName: string;
regionId:number;


}
