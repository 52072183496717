import { Component, OnInit } from '@angular/core';
import { LodgeUser } from "app/management/LodgeCheckIn/lodge-user/lodge-user.pojo";
import { RoomBookingAndUserTransactionPojo } from "app/management/LodgeCheckIn/onlinebooking/onlinebooking.pojo";
import { OnlineBookingService } from "app/management/LodgeCheckIn/onlinebooking/onlinebooking.service";
import { LodgeAppService } from "app/management/LodgeCheckIn/lodge-app/lodge-app.service";
import { CheckInFormService } from "app/management/LodgeCheckIn/check-in-form/check-in-form.service";
import { IdProofTypePojo } from "app/user/excess/required.pojo";
import { RoomRegionService } from "app/management/roomregion/roomregion.service";
import { RoomRegionPojo } from "app/management/roomregion/roomregion.pojo";
import { RoomTypeMasterService } from "app/management/roomtypemaster/roomtypemaster.service";
import { RoomTypeMasterPojo } from "app/management/roomtypemaster/roomtypemaster.pojo";
import { RoomMasterService } from "app/management/roommaster/roommaster.service";
import { ResponsePojo } from "app/management/LodgeCheckIn/Pojo/response.pojo";

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { UserPojo } from 'app/management/management-usermaster/managementuser.pojo';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {

    rms: LodgeUser[] = [];
    lu = new LodgeUser();
    xlPojo: RoomBookingAndUserTransactionPojo[] = [];
    xl = new RoomBookingAndUserTransactionPojo();
    addbooking = new RoomBookingAndUserTransactionPojo();
    id: number;
    public data;
    public filterQuery = '';
    public rowsOnPage = 10;
    public sortBy = 'email';
    public sortOrder = 'asc';
    isLoading: Boolean = true;
    idprooftype: IdProofTypePojo[] = [];
    roomregion: RoomRegionPojo[] = [];
    roomarr: RoomTypeMasterPojo[] = [];
    flag: string;
    pt: string;
    rtms: RoomTypeMasterPojo[] = [];
    responsearr: ResponsePojo[] = [];
    str: string;
    user: UserPojo = new UserPojo();

    images: string[] = [  'https://img.freepik.com/free-photo/pink-armchair-white-living-room-with-copy-space_43614-794.jpg?t=st=1726052187~exp=1726055787~hmac=609f7e7f417ff0b4e410ca6e1076e3f6df02b7c476b77c950e8ed07fe84d1d65&w=740',
        'https://img.freepik.com/free-photo/wood-sideboard-living-room-interior-with-copy-space_43614-828.jpg?t=st=1726052372~exp=1726055972~hmac=faa02a15c7f3ae91b36608c8b36bac74ac96b66d4ed18b22eec862dbb21be137&w=740',
        'https://img.freepik.com/free-photo/green-sofa-white-living-room-with-free-space_43614-834.jpg?t=st=1726052031~exp=1726055631~hmac=098e06c292178446d13603747c9f2bfe51cda8735748bf29c35f354901632a4e&w=740',];
    currentIndex: number = 0;
   

    constructor(private roomtypemasterservice: RoomTypeMasterService, private roomtypeservice: RoomMasterService, private roomregionservice: RoomRegionService, private onlinebookingservice: OnlineBookingService, private checkInFormService: CheckInFormService, private lodgeAppService: LodgeAppService,private authService: AuthService, private router: Router) { }

    ngOnInit() {
        

        this.startAutoSlide();
      
        this.isLoading = false;
        $('#add').on('shown.bs.modal', function () {
            $('#importFile').focus();
        });
        this.checkInFormService.getAllIdProofType().subscribe(log => {
            this.idprooftype = log;
        });
        this.roomregionservice.getRoomRegion().subscribe(log3 => {
            this.roomregion = log3;
        }, error => {
            bootbox.alert('Error');
        });
        this.getAllRoomTypeMaster();
        $('#checkInDate').each(function () {
            $(this).datepicker({
                autoclose: true,
                format: 'dd/mm/yyyy',
                todayHighlight: true
            });

        });
        $(function () {
            $('#checkInTime').datetimepicker({
                format: 'LT',
            });
        });
    }

    getAllRoomTypeMaster() {
        this.roomtypemasterservice.getRoomTypeMasterForOnlineAndOffline().subscribe(log => {
            this.isLoading = false;
            this.rtms = log;
            // alert(JSON.stringify(this.rtms));
        }, error => {
            bootbox.alert('Error');
            this.isLoading = false;
        });
    }

    updateStock(event) {
        this.getJson(event.files[0]);
    }

    getRoomTypeByregionid(id) {
        this.roomtypeservice.getRoomByRegionId(id).subscribe(log3 => {
            this.roomarr = log3;
        }, error => {
            bootbox.alert('Error');
        });
    }

    getRoomTypeByName(roomTypeId) {
        if (this.rtms.find(res => res.roomTypeId == roomTypeId)) {
            // alert(this.rtms.find(res => res.roomTypeId == roomTypeId));
            return this.rtms.find(res => res.roomTypeId == roomTypeId);
        } else {
            return new RoomTypeMasterPojo();
        }
    }

    getRegionIdByName(roomType) {
        if (this.rtms.find(res => res.roomType == roomType)) {
            return this.rtms.find(res => res.roomType == roomType).roomRegionId;
        } else {
            return roomType;
        }
    }

    getCottageIdByName(roomType) {
        if (this.rtms.find(res => res.roomType == roomType)) {
            return this.rtms.find(res => res.roomType == roomType).cottageId;
        } else {
            return roomType;
        }
    }

    getJson(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            this.xlPojo = [];
            let base64: any = reader.result;
            base64 = base64.slice(base64.indexOf(',') + 1, base64.length - 1);
            const workbook = XLSX.read(base64, { type: "base64" });
            let first_sheet_name = workbook.SheetNames[0];
            let worksheet = workbook.Sheets[first_sheet_name];
            let d: RoomBookingAndUserTransactionPojo[] = JSON.parse(JSON.stringify(XLSX.utils.sheet_to_json(worksheet)));
            if (d.length != 0) {
                for (let i = 0; i < d.length; i++) {
                    let rowCount = i + 1;
                    if (d[i].transactionId != undefined && d[i].checkInDate != undefined && d[i].checkInTime != undefined && d[i].roomTypeId != undefined && d[i].fullName != undefined && d[i].postalAddress != undefined && d[i].emailId != undefined && d[i].mobileNumber != undefined && d[i].idProof != undefined && d[i].bookedDate != undefined && d[i].proofNumber != undefined && d[i].amount != undefined) {
                        d[i].status = "SUCCESS";
                        d[i].receiptId = d[i].transactionId;
                        d[i].bookedTime = "00:00";
                        d[i].noOfRooms = 1;

                        
                        //  alert( '1  ' + d[i].checkInDate);
                        // alert( '1  ' +  d[0].bookedDate);

                        if (this.validateXl(d[i], rowCount)) {
                            

                            // d[i].checkInDate = moment(d[i].checkInDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                            // d[i].bookedDate = moment(d[i].bookedDate, "DD/MM/YYYY").format("YYYY-MM-DD");

                            // alert( '2  ' + d[0].checkInDate);
                            // alert( '2  ' +  d[0].bookedDate);

                            // alert(d[i].roomTypeId);

                            let type: RoomTypeMasterPojo = this.getRoomTypeByName(d[i].roomTypeId);
                            // alert( 'asd' + JSON.stringify(type));

                            d[i].noOfDays = Math.floor(d[i].amount / type.roomAmount);
                            if ((type.roomType != undefined) && (type.roomType != '')) {
                                d[i].roomType = type.roomType;
                                d[i].roomTypeId = Number.parseInt(d[i].roomTypeId + "");
                                d[i].roomRegionId = type.roomRegionId;
                                this.xlPojo.push(d[i]);
                                if (i == d.length - 1)
                                    $('#add').modal('hide');
                            } else {
                                bootbox.alert("Invalid RoomTypeId in Row No: " + rowCount);
                                break;
                            }
                        } else {
                            break;
                        }
                    } else {
                        bootbox.alert("Please fill all the Mandatory fields in [ " + rowCount + " ] Row");
                        break;
                    }
                }
            } else {
                bootbox.alert("No Records are found in this XL!");
            }
        };
    }

    validateXl(d: RoomBookingAndUserTransactionPojo, rowCount) {
        d.status = d.status.toUpperCase();
        let result = moment(d.checkInDate, 'DD/MM/YYYY', true).isValid();
        if(result){
            d.checkInDate = moment(d.checkInDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        let result1 = moment(d.bookedDate, 'DD/MM/YYYY', true).isValid();
        if(result1){
            d.bookedDate = moment(d.bookedDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        if (d.status == "SUCCESS" || d.status == "FAILED") {
            // d.checkInDate = moment(d.checkInDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
            if (moment(d.checkInDate, 'YYYY-MM-DD', true).isValid() && moment(d.checkInTime.substr(0, 5), 'HH:mm', true).isValid()) {
                return true;
            } else
                bootbox.alert("Invalid CheckIn Date or Time Format in Row No: " + rowCount);
        } else {
            bootbox.alert("Invalid Status Value in Row No: " + rowCount); 
        }
    }

    updateOnlineBookings() {
        let temparr = [];
        (<HTMLInputElement>document.getElementById('update')).disabled = true;
        $('#updateLoading').addClass('fa fa-spinner fa-spin');
        this.onlinebookingservice.upload(this.xlPojo).subscribe(r => {
            let k = new ResponsePojo();
            k = r;
            bootbox.alert(k.responseText);
            $('#updateLoading').removeClass('fa fa-spinner fa-spin');
            (<HTMLInputElement>document.getElementById('update')).disabled = false;
        }, error => {
            bootbox.alert(error + 'Error');
            $('#updateLoading').removeClass('fa fa-spinner fa-spin');
            (<HTMLInputElement>document.getElementById('update')).disabled = false;
        });
    }

    loadOnlineRooms() {
        document.getElementById('loadingPage').style.display = "show";
        $('#addLoading').addClass('fa fa-spinner fa-spin');
        this.lodgeAppService.loadOnlineRooms().subscribe(result => {
            let run = result;
            if (!result) {
                bootbox.alert("Request Success");
                document.getElementById('loadingPage').style.display = "none";
                $('#addLoading').removeClass('fa fa-spinner fa-spin');
            } else {
                bootbox.alert("Service Error");
                document.getElementById('loadingPage').style.display = "none";
                $('#addLoading').removeClass('fa fa-spinner fa-spin');
            }
        }, error => {
            bootbox.alert(error + 'Error');
            document.getElementById('loadingPage').style.display = "none";
            $('#addLoading').removeClass('fa fa-spinner fa-spin');
        });
    }

    validateEmail(emailField) {
        const mailid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailField.match(mailid) || emailField == '') {
        } else {
            (<HTMLInputElement>document.getElementById('mailid')).value = '';
            alert('Not a valid Mail Id');
            $('#mailid').focus();
        }
    }

    phoneNumValidate(num) {
        const contactNum = /^[6-9]\d{9}$/;
        if (num.match(contactNum) || num == '') {
        } else {
            (<HTMLInputElement>document.getElementById('mobileNo')).value = '';
            alert('Not a valid Number');
            $('#mobileNo').focus();
        }
    }

    getAmountByRoomType(roomTypeId) {
        if (this.roomarr.find(r => r.roomTypeId == roomTypeId)) {
            return this.roomarr.find(r => r.roomTypeId == roomTypeId).roomAmount;
        }
        else {
            return roomTypeId;
        }
    }

    calc() {
        let a = (<HTMLFormElement>document.getElementById('numofDays')).value;
        let b = (<HTMLFormElement>document.getElementById('numofRooms')).value;
        let c = this.getAmountByRoomType((<HTMLFormElement>document.getElementById('roomTypeId')).value);
        this.addbooking.amount = (a * b) * c;
        (<HTMLFormElement>document.getElementById('amount')).value = this.addbooking.amount;
    }

    addBooking() {
        this.addbooking.checkInDate = (<HTMLFormElement>document.getElementById('checkInDate')).value;
        this.addbooking.checkInDate = moment(this.addbooking.checkInDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.addbooking.transactionId = (<HTMLFormElement>document.getElementById('trnsId')).value;
        this.addbooking.receiptId = (<HTMLFormElement>document.getElementById('receiptId')).value;
        this.addbooking.checkInTime = (<HTMLFormElement>document.getElementById('checkInTime')).value;
        this.addbooking.checkInTime = moment(this.addbooking.checkInTime, 'hh:mm a').format('HH:mm:ss');
        this.addbooking.roomTypeId = +(<HTMLFormElement>document.getElementById('roomTypeId')).value;
        this.addbooking.roomRegionId = +(<HTMLFormElement>document.getElementById('regionId')).value;
        this.addbooking.noOfDays = (<HTMLFormElement>document.getElementById('numofDays')).value;
        this.addbooking.noOfRooms = (<HTMLFormElement>document.getElementById('numofRooms')).value;
        this.addbooking.fullName = (<HTMLFormElement>document.getElementById('fullName')).value;
        this.addbooking.city = (<HTMLFormElement>document.getElementById('city')).value;
        this.addbooking.emailId = (<HTMLFormElement>document.getElementById('mailid')).value;
        this.addbooking.mobileNumber = (<HTMLFormElement>document.getElementById('mobileNo')).value;
        this.addbooking.idProof = (<HTMLFormElement>document.getElementById('idProof')).value;
        this.addbooking.proofNumber = (<HTMLFormElement>document.getElementById('proofNo')).value;
        this.addbooking.pincode = (<HTMLFormElement>document.getElementById('pincode')).value;
        this.addbooking.postalAddress = (<HTMLFormElement>document.getElementById('postaladr')).value;
        this.addbooking.amount = (<HTMLFormElement>document.getElementById('amount')).value;
        this.addbooking.amountPerRoom = this.getAmountByRoomType((<HTMLFormElement>document.getElementById('roomTypeId')).value);
        $('#addbookingbtn').addClass('fa fa-spinner fa-spin');
        this.onlinebookingservice.addonlineBookingd(this.addbooking).subscribe(login => {
            this.flag = login.text();
            if (this.flag == "EXISTS") {
                bootbox.alert('Transaction Id is already Exist!');
                $('#addbookingbtn').removeClass('fa fa-spinner fa-spin');
            }
            else {
                bootbox.alert('Successfully Added');
                $('#addbookingbtn').removeClass('fa fa-spinner fa-spin');
                $('#addonlinebooking').modal('hide');
                $('.modal-backdrop').hide();
                (<HTMLFormElement>document.getElementById('addbookingform')).reset();
            }
        }, error => {
            bootbox.alert('Error!! ' + error)
            $('#addbookingbtn').removeClass('fa fa-spinner fa-spin');
        });
    }
    closeAdd() {
        $('#addonlinebooking').modal('hide');
        (<HTMLFormElement>document.getElementById('addbookingform')).reset();
    }

    startAutoSlide(interval: number = 3000) {
        setInterval(() => {
          this.nextSlide();
        }, interval);
      }
    
      nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      }
    
      prevSlide() {
        this.currentIndex =
          (this.currentIndex - 1 + this.images.length) % this.images.length;
      }
    
      goToSlide(index: number) {
        this.currentIndex = index;
      }

      logout() {
        this.authService.logout();  // Reset the authentication flag
        this.router.navigate(['/userlogin']);  // Redirect to login page
      }
}