export class RoomBookingAndUserTransactionPojo {
    roomBookingId: number;
   checkInDate: string;
    checkInTime: string;
    roomName: string;
    roomTypeId: number;
    roomRegionId: number;
    roomBookingSerialId: number;
    bookedDate: string;
    bookedTime: string;
    bookingForAccountHolderFlag: string;
    devoteeUserId: number;
    noOfRooms: number;
    noOfDays: number;
    fullName: string;
    postalAddress: string;
    city: string;
    pincode: string;
    emailId: string;
    mobileNumber: string;
    idProof: string;
    proofNumber: string;
    amount: number;
    transactionId: string;
    status: string;
    receiptId: string;
    amountPerRoom: number;
    bookedOn: Date;
    bookingDate: Date;
accountType: string;
accountId: number;
pgProviderName: string;
eServiceType: string;
eServiceId: number;
pgPayUId: string;
pgMode: string;
pgAccountKey: string;
transactionInfo: string;
firstName: string;
pgError: string;
pgBankCode: string;
pgType: string;
pgBankRefNum: string;
pgUnMappedStatus: string;
email: string;
clientIpAddress: string;
roomType:string;
//cottageId:number;
}