import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { StaticVariable } from '../../globle.class';
import { RoomRegionPojo } from "app/management/roomregion/roomregion.pojo";
declare var bootbox: any;

const httpOptions = {
    headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
    //  headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
};

@Injectable()
export class RoomRegionService {
    constructor( private httpclient: HttpClient, private http: Http ) { }
    getRoomRegion(): Observable<RoomRegionPojo[]> {
        const url = StaticVariable.porturl + 'RoomRegionAll';
        return this.httpclient.get<RoomRegionPojo[]>( url );
    }
    addRoomRegion( arg: RoomRegionPojo ): Observable<any> {
        // alert('url');
        const url = StaticVariable.porturl + 'AddRoomRegion';
        return this.http.post( url, arg );
    }
    deleteRoomRegion( arg: number ): Observable<any> {
        // alert('del');
        const url = StaticVariable.porturl + 'RoomRegionDelete/' + arg;
        return this.httpclient.delete<any>( url );
    }
    updateRoomRegion( data: RoomRegionPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'RoomRegionUpdate';
        return this.http.put( url, data );
    }

}
