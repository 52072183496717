import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from "rxjs";
import { StaticVariable } from "app/globle.class";
import { CottagePojo } from "app/management/cottagemaster/cottage.pojo";
import { RoomBookingAndUserTransactionPojo } from "app/management/LodgeCheckIn/onlinebooking/onlinebooking.pojo";

const httpOptions = {
    headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
    //  headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
};

@Injectable()

export class OnlineBookingService {

    constructor( private http: Http, private httpclient: HttpClient ) { }

    upload( data: RoomBookingAndUserTransactionPojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'UploadRoomBookingAndTransaction';
        return this.httpclient.post( url, data );
      
    }
    addonlineBookingd( arg: RoomBookingAndUserTransactionPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddRoomBookingAndTransaction';
        return this.http.post( url, arg );
    }
    
    
}