import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { UserPojo } from 'app/management/management-usermaster/managementuser.pojo';
import { AuthService } from '../auth.service';
import { ResponsePojo } from 'app/management/LodgeCheckIn/Pojo/response.pojo';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: UserPojo = new UserPojo();
  isAuthenticated: boolean;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
  }

  mobile: string = '';
  password: string = '';
  showUpdateModal: boolean = false;
 
  onSubmit() {
    const userLogin: UserPojo = {
      cuserId: 0,
      mobileNo: this.mobile,
      password: this.password,
      name: '',
      rpojo: new ResponsePojo(),
    };

    this.authService.login(userLogin).subscribe(
      (response) => {
        console.log(response);  // Log the entire response to confirm success
        if (response.rpojo.responseText === 'SUCCESS') {  // Check explicitly for success
          this.router.navigate(['/user']);  // Navigate to /user if login is successful
        } else {
          alert('Invalid credentials');  // Show error for other responses
        }
      },
      (error) => {
        alert('Login failed. Please try again.');  // Handle login failure
      }
    );
    
  }


  // onSubmit() {
  //   const userLogin: UserPojo = {
  //     cuserId: 0, // Adjust or set based on your requirements
  //     mobileNo: this.mobile,
  //     password: this.password,
  //     name: '',
  //     responsepojo: new ResponsePojo() // Initialize as per your setup
  //   };
  
  //   this.authService.login(userLogin).subscribe(
  //     (response) => {
        
  //         // Handle specific error messages from responseText
  //         if (response.rpojo.responseText === "INVALID PASSWORD") {
  //           alert('Invalid credentials: Incorrect password'); // Show specific error message
  //         } else {
  //           this.router.navigate(['/user']);  // Display the response text for other errors
  //         }
        
  //     },
  //     (error) => {
  //       alert('Login failed. Please try again.'); // General error handling
  //     }
  //   );
  // }
  
  openUpdateModal(): void {
    this.showUpdateModal = true;
  }

  closeUpdateModal(): void {
    this.showUpdateModal = false;
  }


  update(): void {
    if (this.user) {
      this.authService.updatePassword(this.user).subscribe({
        next: (response) => {
          console.log('Password updated successfully', response);
          this.closeUpdateModal(); // Close modal on success
        },
        error: (error) => {
          console.error('Error updating password', error);
          // Handle error scenario, e.g., show an error message to the user
        }
      });
    }
  }

  logout() {
    this.authService.logout();  // Reset the authentication flag
    this.router.navigate(['/login']);  // Redirect to login page
  }

}
