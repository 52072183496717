import { LoggedUserDetails } from "app/management/excess/loggeduserdetail.pojo";
import { ResponsePojo } from "../LodgeCheckIn/Pojo/response.pojo";

export class ManagementUserPojo {
    userId: number;
    roleId: number;
    userName: string;
    password: string;
    name: string;
    mobile: string;
    address: string;
    emailId: string;
    role: string;
    rolekey: string;
    designation: string;
    proofType: string;
    proofNo: string;
    regionId: number;
    cottageId: number;
    loggedUserDetails = new LoggedUserDetails();
}

export class ManagementRolePojo {
    lodgeapp = false;
    checkin = false;
    roomstatus = false;
    roomcleaning = false;
    checkinhistory = false;
    roomtransfer = false;
    onlinebooking = false;
    roomtransferform = false;
    roomtransferhistory = false;
    lodgereport = false;
    cottagereport = false;
    roomreport = false;
    lodgesettings = false;
    roomblocking = false;
    master = false;
    roomregion = false;
    cottagemaster = false;
    roomtypemaster = false;
    roommaster = false;
    rolemaster = false;
    musermaster = false;
    blockeddatemaster = false;
    receiptitem = false;
    chittatable = false;
    billmaster = false;
    onlineroombookinghistory = false;
    usertransactionhistory = false;
    statement = false;
    cashbook = false;
    cashbookmaster = false;
    cashbookreport = false;
    depositreport = false;
    abstractreport = false;
}



export class UserPojo {
    cuserId: number;
    password: string;
    name: string;
    mobileNo: string;
    rpojo=new ResponsePojo();
    
}