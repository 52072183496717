
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.checkAuthenticated()) {
      return true;  // Allow access if authenticated
    } else {
      this.router.navigate(['/user']);  // Redirect to login if not authenticated
      return false;
    }
  }
}

