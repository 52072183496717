export class StaticVariable {

    //static porturl = 'http://localhost:8080/LodgeWebApi/';
  /// static porturl = 'http://localhost:8080/LodgeWebApi/';
      // static porturl = 'http://localhost:8080/LodgeWebApi/';
      static porturl = 'http://148.66.133.79:8080/LodgeWebApi/';
  //  static porturl ='http://192.168.1.35:8022/LodgeWebApi/';
  // static porturl = 'http://localhost:8022/LodgeWebApi/';
    static status_1 = 'INITIAL';
    static status_2 = 'SUCCESS';
    static status_3 = 'FAILED';
    static status_4 = 'PENDING';
    static smsEncryptOffset = 25;
    static technicalSupport = '8754097359';
    static mail1 = '';
    
    static mail2 = '';
    static landLine = '';
    static tollFree = '';
    static pgKey1 = '';
    static pgKey2 = '';
    static pgSalt1 = '';
    static pgSalt2 = '';
    // static templeName = 'ARULMIGU SUBRAMANYA SWAMY THIRUKKOIL';
    // static placeName = 'TIRUCHENDUR - 628215';
    // static titleName = 'ARULMIGU SUBRAMANYA SWAMY THIRUKKOIL';
    // static footerName = 'Arulmigu Subramanya Swamy Thirukkoil';
    static templeName='ARULMIGU RAMANATHASWAMY TEMPLE';
    static placeName='RAMANATHAPURAM';
    static titleName='ARULMIGU RAMANATHASWAMY TEMPLE';
    static footerName ='Arulmigu Ramanathaswamy Temple';
    static cottageIncomeFlag = true;
    static allRoomsCheckInFlag = true;
    static receiptMRFlag = false;
}
