import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UserPojo } from 'app/management/management-usermaster/managementuser.pojo';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  user: UserPojo = new UserPojo();

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}

  onSubmit(form: NgForm): void {
    if (form.valid) {
      // Check if the mobile number already exists before registration
      this.authService.checkMobileNoForRegisterVerification(this.user.mobileNo).subscribe(
        response => {
          // Assuming responseBoolean is true if the mobile number is already registered
          if (response.responseBoolean) {
            window.alert('This mobile number is already registered. Please use a different number or login.');
          } else {
            // Proceed with registration since the mobile number is not registered
            this.authService.register(this.user).subscribe(
              response => {
                console.log('Registration successful', response);
                this.router.navigate(['/userlogin']); // Redirect to login page on successful registration
              },
              error => {
                console.error('Registration failed', error);
                if (error.status === 400) {
                  window.alert('Invalid registration details. Please check your input and try again.');
                } else if (error.status === 500) {
                  window.alert('Server error occurred. Please try again later.');
                } else {
                  window.alert('Registration failed. Please try again.');
                }
              }
            );
          }
        },
        error => {
          console.error('Error checking mobile number', error);
          window.alert('An error occurred while verifying the mobile number. Please try again.');
        }
      );
    } else {
      console.error('Form is invalid');
      window.alert('Please correct the errors in the form.');
    }
  }
  
 
}
