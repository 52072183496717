import { LoggedUserDetails } from "app/management/excess/loggeduserdetail.pojo";
export class RoomTypeMasterPojo {
    roomTypeId: number;
    roomType: string;
    roomRegionId: number;
    roomAmount: number;
    maximumNoOfPerson: number;
    maximumNoOfRooms: number;
    maximumNoOfDays: number;
    numberOfRoomsPerPerson: number;
    tax: number;
    cottageId: number;
    onlineFlag: string;
    depositAmount: number;
    maximumExtraPersons:number;
    amountPerExtraPerson:number;
    onlineDepositAmount:number;
    loggedUserDetails = new LoggedUserDetails();
}